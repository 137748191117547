import styled, { css } from 'styled-components'
import { font } from '../vars/font.style'
import { clamp } from '../utils/conversion.style'

export const textStylesConfig = {
  heading1: {
    size: {
      min: 47,
      max: 166,
    },
    lineHeight: 1,
  },
  heading2: {
    size: {
      min: 44,
      max: 126,
    },
    lineHeight: 1.01,
  },
  heading3: {
    size: {
      min: 26,
      max: 34,
    },
    lineHeight: 1.01,
  },
  heading4: {
    size: {
      min: 32,
      max: 64,
    },
    lineHeight: 1.125,
  },
  bodyLarge: {
    size: {
      min: 26,
      max: 36,
    },
    lineHeight: 1.2,
  },
  body: {
    size: {
      min: 17,
      max: 21,
    },
    lineHeight: 1.2,
  },
  bodyAdvisor: {
    size: {
      min: 16,
      max: 24,
    },
    lineHeight: 1.2,
  },
  bodySmall: {
    size: {
      min: 12,
      max: 16,
    },
    lineHeight: 1.2,
  },
  caption: {
    size: {
      min: 16,
      max: 20,
    },
    lineHeight: 1.18,
  },
  cta: {
    size: {
      min: 14,
      max: 16,
    },
    lineHeight: 1.1,
  },
  uiLabel: {
    size: {
      min: 12,
      max: 16,
    },
    lineHeight: 1.125,
  },
}

export const Heading1Styles = css`
  font-family: ${font.primary.family};
  font-weight: ${font.primary.weight.regular};
  line-height: ${textStylesConfig.heading1.lineHeight};
  letter-spacing: -0.02em;
  ${clamp(
    'font-size',
    textStylesConfig.heading1.size.min,
    textStylesConfig.heading1.size.max
  )}
`

export const Heading2Styles = css`
  font-family: ${font.primary.family};
  font-weight: ${font.primary.weight.regular};
  line-height: ${textStylesConfig.heading2.lineHeight};
  ${clamp(
    'font-size',
    textStylesConfig.heading2.size.min,
    textStylesConfig.heading2.size.max
  )}
`

export const Heading3Styles = css`
  font-family: ${font.primary.family};
  font-weight: ${font.primary.weight.regular};
  line-height: ${textStylesConfig.heading3.lineHeight};
  text-transform: uppercase;
  ${clamp(
    'font-size',
    textStylesConfig.heading3.size.min,
    textStylesConfig.heading3.size.max
  )}
`

export const Heading4Styles = css`
  font-family: ${font.primary.family};
  font-weight: ${font.primary.weight.regular};
  line-height: ${textStylesConfig.heading4.lineHeight};
  ${clamp(
    'font-size',
    textStylesConfig.heading4.size.min,
    textStylesConfig.heading4.size.max
  )}
`

export const TextBodyLargeStyles = css`
  font-family: ${font.primary.family};
  font-weight: ${font.primary.weight.regular};
  line-height: ${textStylesConfig.bodyLarge.lineHeight};
  letter-spacing: -0.01em;
  ${clamp(
    'font-size',
    textStylesConfig.bodyLarge.size.min,
    textStylesConfig.bodyLarge.size.max
  )}
`

export const TextBodyStyles = css`
  font-family: ${font.primary.family};
  font-weight: ${font.primary.weight.regular};
  line-height: ${textStylesConfig.body.lineHeight};
  letter-spacing: -0.01em;
  ${clamp(
    'font-size',
    textStylesConfig.body.size.min,
    textStylesConfig.body.size.max
  )}
`

export const TextBodyAdvisorStyles = css`
  font-family: ${font.primary.family};
  font-weight: ${font.primary.weight.regular};
  line-height: ${textStylesConfig.bodyAdvisor.lineHeight};
  letter-spacing: -0.01em;
  ${clamp(
    'font-size',
    textStylesConfig.bodyAdvisor.size.min,
    textStylesConfig.bodyAdvisor.size.max
  )}
`

export const TextBodySmallStyles = css`
  font-family: ${font.primary.family};
  font-weight: ${font.primary.weight.regular};
  line-height: ${textStylesConfig.bodySmall.lineHeight};
  letter-spacing: 0.01em;
  ${clamp(
    'font-size',
    textStylesConfig.bodySmall.size.min,
    textStylesConfig.bodySmall.size.max
  )}
`

export const TextUILabelStyles = css`
  font-family: ${font.primary.family};
  font-weight: ${font.primary.weight.regular};
  line-height: ${textStylesConfig.uiLabel.lineHeight};
  text-transform: uppercase;
  ${clamp(
    'font-size',
    textStylesConfig.uiLabel.size.min,
    textStylesConfig.uiLabel.size.max
  )}
`

export const Heading1 = styled.p`
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}em` : undefined)};
  ${Heading1Styles}
  color: ${props => (props.color ? props.color : undefined)};
`

export const Heading2 = styled.p`
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}em` : undefined)};
  ${Heading2Styles}
  color: ${props => (props.color ? props.color : undefined)};
`

export const Heading3 = styled.p`
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}em` : undefined)};
  ${Heading3Styles}
  color: ${props => (props.color ? props.color : undefined)};
`

export const Heading4 = styled.p`
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}em` : undefined)};
  ${Heading4Styles}
  color: ${props => (props.color ? props.color : undefined)};
`

export const TextBodyLarge = styled.p`
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}em` : undefined)};
  ${TextBodyLargeStyles}
  color: ${props => (props.color ? props.color : undefined)};
`

export const TextBody = styled.p`
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}em` : undefined)};
  ${TextBodyStyles}
  color: ${props => (props.color ? props.color : undefined)};
`

export const TextBodyAdvisor = styled.p`
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}em` : undefined)};
  ${TextBodyAdvisorStyles}
  color: ${props => (props.color ? props.color : undefined)};
`

export const TextBodySmall = styled.p`
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}em` : undefined)};
  ${TextBodySmallStyles}
  color: ${props => (props.color ? props.color : undefined)};
`

export const TextUILabel = styled.p`
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}em` : undefined)};
  ${TextUILabelStyles}
  color: ${props => (props.color ? props.color : undefined)};
`
