import React from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'
import { ScrollBlock } from './index.style'

const ScrollSection = ({
  threshold,
  trackInView,
  triggerOnce,
  children,
  ...props
}) => (
  <div data-scroll-section {...props}>
    {trackInView ? (
      <InView triggerOnce={triggerOnce} threshold={threshold}>
        {({ inView, ref }) => (
          <div ref={ref} data-inview={inView.toString()}>
            <ScrollBlock>
              {React.cloneElement(children, { inView: inView })}
            </ScrollBlock>
          </div>
        )}
      </InView>
    ) : (
      <ScrollBlock>{children}</ScrollBlock>
    )}
  </div>
)

ScrollSection.propTypes = {
  children: PropTypes.node.isRequired,
  threshold: PropTypes.number,
  trackInView: PropTypes.bool,
  triggerOnce: PropTypes.bool,
}

ScrollSection.defaultProps = {
  threshold: 0.1,
  trackInView: true,
  triggerOnce: true,
}

export default ScrollSection
