import React from 'react'
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  TextBodyLarge,
  TextBody,
  TextBodyAdvisor,
  TextBodySmall,
  TextUILabel,
} from '../../styles/vars/textStyles.style'

const ThisHeading1 = ({ children, as, color, maxWidth, ...props }) => (
  <Heading1 as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </Heading1>
)

const ThisHeading2 = ({ children, as, color, maxWidth, ...props }) => (
  <Heading2 as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </Heading2>
)

const ThisHeading3 = ({ children, as, color, maxWidth, ...props }) => (
  <Heading3 as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </Heading3>
)

const ThisHeading4 = ({ children, as, color, maxWidth, ...props }) => (
  <Heading4 as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </Heading4>
)

const ThisTextBodyLarge = ({ children, as, color, maxWidth, ...props }) => (
  <TextBodyLarge as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </TextBodyLarge>
)

const ThisTextBody = ({ children, as, color, maxWidth, ...props }) => (
  <TextBody as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </TextBody>
)

const ThisTextBodyAdvisor = ({ children, as, color, maxWidth, ...props }) => (
  <TextBodyAdvisor as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </TextBodyAdvisor>
)

const ThisTextBodySmall = ({ children, as, color, maxWidth, ...props }) => (
  <TextBodySmall as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </TextBodySmall>
)

const ThisTextUILabel = ({ children, as, color, maxWidth, ...props }) => (
  <TextUILabel as={as} color={color} maxWidth={maxWidth} {...props}>
    {children}
  </TextUILabel>
)

export {
  ThisHeading1 as Heading1,
  ThisHeading2 as Heading2,
  ThisHeading3 as Heading3,
  ThisHeading4 as Heading4,
  ThisTextBodyLarge as TextBodyLarge,
  ThisTextBody as TextBody,
  ThisTextBodyAdvisor as TextBodyAdvisor,
  ThisTextBodySmall as TextBodySmall,
  ThisTextUILabel as TextUILabel,
}
